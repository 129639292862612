import React from "react";

import {
  NavBar,
  Footer,
} from "../../components/index";

const PrivacyPolicy = () => {
  return (
    <>
      <NavBar />
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
