import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import './App.css'

import Home from './views/home/Home'
import Projects from './views/projects/Projects'
import NoPage from './views/noPage/NoPage'
import PrivacyPolicy from './views/privacyPolicy/PrivacyPolicy'

import AOS from "aos";
import "aos/dist/aos.css";

const App = () => {
  
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Routes>
        <Route path='/' element={<Home />}/>
        <Route path='/projekty' element={<Projects />}/>
        <Route path='/PrivacyPolicy' element={<PrivacyPolicy />}/>
        <Route path="*" element={<NoPage />} />
      </Routes>
    </>
  )
}

export default App