import React, { useEffect, useState } from "react";

import {
  NavBar,
  Hero,
  AboutUs,
  Features,
  Portfolio,
  Faq,
  Contact,
  Footer
} from "../../components/index";

const Home = () => {
  document.title = "HighHome.pl :: Kompleksowa Budowa Domu na Zamówienie";

  const [data, setData] = useState();

  async function logJSONData() {
    const response = await fetch(
      "https://cdn.contentful.com/spaces/be9fg2lvstiv/entries/?content_type=portfolio&access_token=vQ2zABxDf5ILJstpjSSmgBTgtBaLXTg_DHif_b7ueOk"
    );
    const jsonData = await response.json();
    setData(jsonData);
  }

  useEffect(() => {
    logJSONData();
  }, []);

  useEffect(() => {
    getAssets();
  }, [])

  const getAssets = () => {

    const aAssets = [];

    data?.includes?.Asset.forEach((assets) => {
      aAssets.push({ 'url': assets.fields.file.url })
    })

    return aAssets;
  }

  return (
    <>
      <NavBar />
      <Hero />
      <AboutUs />
      <Features />
      {
        
        data?.items.map((item, index) => {

          const iOrder = (item?.fields?.order === 1) ? "1" : "0" || null;
          const sTitle = item?.fields?.title || null;
          const assetList = getAssets();
          const aDescription = []
          const images = [];

          item?.fields?.description?.content.forEach((item) => {
            item?.content?.forEach((item) => {
              aDescription.push('<p className="max-w-xl mt-4 text-base tracking-tight text-gray-600">' + item.value + '</p>')
            })
          })

          item?.fields?.images?.forEach((sImage) => {
            assetList.forEach((asset) => {
              if (asset.url.includes(sImage.sys.id)) {
                images.push({
                  url: asset.url
                })
              }
            })
          })

          return (
            <Portfolio key={index} data={{

              displaySettings: { order: iOrder },
              content: {
                key: 0,
                title: sTitle,
                description: aDescription,

                assets: images || null

              }
            }
            } />
          )
        })
      }
      <Faq />
      <Contact />
      <Footer />
      {/* <CallToUs/> */}
    </>
  );
};

export default Home;
