import React from "react";
import heroBackgorund from "../../assets/images/featuresBackgorund.png";
import {
  TfiUser,
  TfiHummer,
  TfiRulerPencil,
  TfiSearch,
  TfiKey,
} from "react-icons/tfi";
import videos from "../../assets/images/homeMov.mp4";

const Features = () => {
  return (
    <>
      <section
        id="services"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, 0.1) 0%, rgba(0, 0, 0, 0.8) 100%),url(${heroBackgorund})`,
          backgroundPosition: "center top",
          backgroundSize: "cover",
          padding: "2rem",
        }}
      >
        <div className="relative items-center w-full md:px-5 pt-24 mx-auto md:px-12 lg:px-16 max-w-7xl lg:py-24">
          <div>
            <div className="max-w-2xl">
              <p className="text-2xl font-medium tracking-tight text-white sm:text-4xl">
                Warto skorzystać z doświadczenia profesjonalistów. Nasza usługa
                składa się z kilku etapów. Współpraca z nami może być dowolnie
                konfigurowana, wszystko zależy od indywidualnych ustaleń
              </p>
              <p
                style={{
                  textShadow: "0px 1px 1px #000",
                }}
                className="max-w-3xl mt-4 text-lg tracking-tight text-white"
              >
                Osoby zainteresowane naszą usługą, zapraszamy na
                niezobowiązujące spotkanie. Aby poznać szczegóły współpracy z
                nami.
              </p>
            </div>
          </div>
          <div className="">
            <div className="max-w-xl py-12 mx-auto text-left lg:max-w-7xl">
              <h2 className="sr-only">Features.</h2>
              <div>
                <div className="grid grid-cols-1 gap-4 md:gap-12 lg:grid-cols-3 lg:space-y-0">
                  <div
                    style={{
                      backgroundColor: "rgba(255,255,255,0.90)",
                      borderLeft: "5px solid #ca8a04",
                    }}
                    className="bg-slate-50 p-3  drop-shadow-md drop-shadow-md"
                    data-aos="fade-down"
                    data-aos-delay="100"
                    data-aos-offset="100"
                  >
                    <div>
                      <div className="text-yellow-600 flex items-center justify-center w-12 h-12 text-black  rounded-xl text-5xl">
                        <TfiUser />
                      </div>
                      <p className="mt-4 text-lg font-medium leading-6 text-black"></p>
                    </div>
                    <div
                      className="mt-4 text-base text-gray-500 "
                      style={{
                        color: "#1f1f1f",
                      }}
                    >
                      Spotkanie i dokładny wywiad z klientem, określenie
                      oczekiwań, budżetu, lokalizacji.
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "rgba(255,255,255,0.90)",
                      borderLeft: "5px solid #ca8a04",
                    }}
                    className="bg-slate-50 p-3  drop-shadow-md"
                    data-aos="fade-down"
                    data-aos-delay="120"
                    data-aos-offset="100"
                  >
                    <div>
                      <div className="text-yellow-600 flex items-center justify-center w-12 h-12 text-black  rounded-xl text-5xl">
                        <TfiSearch />
                      </div>
                      <p className="mt-4 text-lg font-medium leading-6 text-black"></p>
                    </div>
                    <div
                      style={{
                        color: "#1f1f1f",
                      }}
                      className="mt-4 text-base text-gray-500"
                    >
                      Znalezienie odpowiedniej działki, weryfikacja stanu
                      prawnego, możliwości zabudowy, dostępności mediów.
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "rgba(255,255,255,0.90)",
                      borderLeft: "5px solid #ca8a04",
                    }}
                    className="bg-slate-50 p-3  drop-shadow-md"
                    data-aos="fade-down"
                    data-aos-delay="140"
                    data-aos-offset="100"
                  >
                    <div>
                      <div className="text-yellow-600 flex items-center justify-center w-12 h-12 text-black  rounded-xl text-5xl">
                        <TfiRulerPencil />
                      </div>
                      <p className="mt-4 text-lg font-medium leading-6 text-black"></p>
                    </div>
                    <div
                      style={{
                        color: "#1f1f1f",
                      }}
                      className="mt-4 text-base text-gray-500"
                    >
                      Wspólne zaprojektowanie domu, uzyskanie niezbędnych
                      uzgodnień, uzyskanie pozwolenia na budowę
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "rgba(255,255,255,0.90)",
                      borderLeft: "5px solid #ca8a04",
                    }}
                    className="bg-slate-50 p-3  drop-shadow-md"
                    data-aos="fade-down"
                    data-aos-delay="160"
                    data-aos-offset="100"
                  >
                    <div>
                      <div className="text-yellow-600 flex items-center justify-center w-12 h-12 text-black  rounded-xl text-5xl">
                        <TfiHummer />
                      </div>
                      <p className="mt-4 text-lg font-medium leading-6 text-black"></p>
                    </div>
                    <div
                      style={{
                        color: "#1f1f1f",
                      }}
                      className="mt-4 text-base text-gray-500"
                    >
                      Kompleksowa budowa domu do stanu tzw. "deweloperskiego",
                      odbiór budynku
                    </div>
                  </div>
                  <div
                    style={{
                      backgroundColor: "rgba(255,255,255,0.90)",
                      borderLeft: "5px solid #ca8a04",
                    }}
                    className="bg-slate-50 p-3  drop-shadow-md"
                    data-aos="fade-down"
                    data-aos-delay="180"
                    data-aos-offset="100"
                  >
                    <div>
                      <div className="text-yellow-600 flex items-center justify-center w-12 h-12 text-black  rounded-xl text-5xl">
                        <TfiKey />
                      </div>
                      <p className="mt-4 text-lg font-medium leading-6 text-black"></p>
                    </div>
                    <div
                      style={{
                        color: "#1f1f1f",
                      }}
                      className="mt-4 text-base text-gray-500"
                    >
                      Wykończenie domu pod klucz
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Features;
