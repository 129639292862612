import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  TfiFacebook,
} from "react-icons/tfi";
const Footer = () => {

  return (
    <>
      <footer className="bg-slate-50">
        <div className="px-4 py-12 mx-auto overflow-hidden max-w-7xl sm:px-6 lg:px-8">
          <nav
            className="flex flex-wrap justify-center -mx-5 -my-2"
            aria-label="Footer"
          >
            <div className="px-5 py-2">
              <Link to={'/'} className="text-sm text-gray-500 hover:text-yellow-600">
                Polityka prywatności
              </Link>
            </div>
            <div className="px-5 py-2">
              <Link to={'/'} className="text-sm text-gray-500 hover:text-yellow-600">
                O firmie HigHome
              </Link>
            </div>
            <div className="px-5 py-2">
              <Link to={'/'} className="text-sm text-gray-500 hover:text-yellow-600">
                Pomoc
              </Link>
            </div>
          </nav>
          <div className="flex justify-center mt-8 space-x-6">
            <span className="inline-flex justify-center w-full gap-3 m-auto md:justify-start md:w-auto text-xl">
              <Link to={"https://www.facebook.com/profile.php?id=100091927383839"} className="w-6 h-6 transition fill-black hover:text-yellow-600">
                <TfiFacebook />
              </Link>
              {/* <Link to={"/"} className="w-6 h-6 transition fill-black hover:text-yellow-600">
                <TfiInstagram />
              </Link>
              <Link to={"/"} className="w-6 h-6 transition fill-black hover:text-yellow-600">
                <TfiLinkedin />
              </Link> */}
            </span>
          </div>
          <p className="mt-8 text-center">
            <span className="mx-auto mt-2 text-sm text-gray-500">
              Copyright &copy; 2023 by HigHome.pl
            </span>
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
