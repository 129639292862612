import React, { useState } from "react";
import logo from "../../assets/images/HH_logo.png";
import { HashLink } from 'react-router-hash-link';


const NavBar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const setMenuState = () => {
    isMenuOpen ? setMenuOpen(false) : setMenuOpen(true);
  };

  const navigation = [
    {
      id: "0",
      uri: "#aboutUs",
      label: "O nas",
    },
    {
      id: "1",
      uri: "#services",
      label: "Usługi",
    },
    {
      id: "2",
      uri: "#projects",
      label: "Realizacje",
    },
    {
      id: "3",
      uri: "#realization",
      label: "Porady",
    },
    {
      id: "4",
      uri: "projekty",
      label: "Projekty",
    }
  ]

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  }

  return (
    <>
      <div className="bg-white drop-shadow-md sticky top-0 z-40">
        <div className="absoulte md:relative flex flex-col w-full p-5 mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
          <div className="flex flex-row items-center justify-between lg:justify-start">
            <HashLink
              to="/#home"
              scroll={el => scrollWithOffset(el)}
              smooth={true}
              className="text-lg tracking-tight text-black uppercase focus:outline-none focus:ring lg:text-2xl"
            >
              <span className="lg:text-lg uppecase focus:ring-0">
                <img src={logo} className="w-36	lg:w-72 cursor-pointer" />
              </span>
            </HashLink>
            <button className="md:hidden inline-flex items-center justify-center p-2 text-gray-400 hover:text-black focus:outline-none focus:text-black">
              <button
                onClick={() => {
                  setMenuState();
                }}
              >
                <svg
                  className="w-6 h-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    className={isMenuOpen ? "hidden" : " inline-flex"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                  <path
                    className={isMenuOpen ? "" : "hidden"}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </button>
          </div>
          <nav
            className={
              isMenuOpen
                ? " flex flex-col justify-start items-start	mt-5 mb-5 md:mt-0 md:mb-0 md:items-center flex-grow md:pb-0 md:flex md:justify-end md:flex-row "
                : " hidden flex-col items-center flex-grow md:pb-0 md:flex md:justify-end md:flex-row"
            }
          >
            <div className="lg:ml-auto flex flex-col md:flex-row">
              {navigation.map((val, key) => {
                return (
                  <HashLink
                    smooth={true}
                    scroll={el => scrollWithOffset(el)}
                    key={key}
                    className="px-2 py-2 text-sm text-gray-500 lg:px-6 md:px-3 hover:text-yellow-600 lg:text-lg uppercase cursor-pointer "
                    to={"/" + val.uri}
                    onClick={() => { setMenuOpen(false) }}
                  >
                    {val.label}
                  </HashLink>
                )
              })}

            </div>
            <div className="inline-flex items-center gap-2 list-none lg:ml-auto">
              <HashLink
                smooth={true}
                scroll={el => scrollWithOffset(el)}
                to="#contact"
                onClick={() => { setMenuOpen(false) }}
              >
                <button className="inline-flex items-center justify-center mt-5 md:mt-0 px-4 py-2 text-sm font-semibold text-white bg-black group focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 hover:bg-gray-700 active:bg-gray-800 active:text-white focus-visible:outline-black">
                  Kontakt
                </button>
              </HashLink>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default NavBar;
