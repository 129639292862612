import React, { useState, useEffect } from "react";
import Slider from "react-slick";

import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

const Portfolio = (props) => {

  const [data, setData] = useState();
  
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  useEffect(() => {
    setData(props.data);
  }, [props]);


  const htmlDecode = (sData) => {
    return { __html: sData };
  }

  return (
    <>
      <section
        id="projects"
        className={data?.displaySettings?.order === "1"?' bg-white ':' bg-slate-50' + " relative flex items-center w-full overflow-hidden"}
      >
        <div className="relative items-center w-full md:px-5 md:py-24 mx-auto md:px-12 lg:px-16 max-w-7xl">
          <div className="relative flex-col items-start m-auto align-middle">
            <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-12">
              <div
                className={
                  data?.displaySettings?.order === "1"
                    ? "min-h-[250px] md:min-h-fit md:order-first flex justify-center items-center"
                    : "" + "min-h-[250px] md:min-h-fit flex justify-center items-center"
                }
                data-aos="fade-down"
                data-aos-delay="100"
                data-aos-offset="100"
              >
                <div className="max-w-xl text-center lg:text-left">
                  <div>
                    <p className="text-2xl font-medium tracking-tight text-black sm:text-4xl">
                      {data?.content?.title || null}
                    </p>
                    <div dangerouslySetInnerHTML={htmlDecode(data?.content?.description)} />
                  </div>
                </div>
              </div>
              <div className="order-first mb-12 md:mb-0">
                <Slider
                  {...settings}
                  data-aos="fade-left"
                  data-aos-delay="100"
                  data-aos-offset="100"
                >
                  {
                    data?.content?.assets?.map((value, index) => {
                      return (
                        <div className="cursor-grab" key={index || null}>
                          <img
                            className="object-cover object-center w-full mx-auto 300 lg:ml-auto "
                            alt={'https:' + value.url || null}
                            src={'https:' + value.url || null}
                          />
                        </div>
                      )

                    })

                  }
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;