import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import {
  TfiStar,
  TfiHome,
  TfiHummer,
  TfiRulerPencil,
  TfiRulerAlt2,
} from "react-icons/tfi";
import video360p from "../../assets/videos/homeMov360p.mp4";
import video480p from "../../assets/videos/homeMov480p.mp4";
import video720p from "../../assets/videos/homeMov720p.mp4";

const AboutUs = () => {
  const [counterOn, setCounterOn] = useState(false);
  const [screenWidth, setScreenWidth] = useState(null);

  useEffect(() => {
    setScreenWidth(window.innerWidth);
  }, [screenWidth]);

  return (
    <>
      <section id="aboutUs" className="bg-slate-50">
        <div className="relative w-full md:px-0 md:pt-0 mx-auto md:px-12 lg:px-16 max-w-7xl lg:py-24">
          <div
            className="grid grid-cols-1 md:grid-cols-2"
            style={{
              border: "1px solid #eee",
            }}
          >
            <div className="w-full bg-white flex flex-col justify-center">
              <p className="p-6 pb-0 text-2xl font-medium tracking-tight sm:text-4xl">
                PRZYKŁADOWE REALIZACJE
              </p>
              <p className="p-6 pt-0 max-w-3xl mt-4 text-lg tracking-tight">
                Przedstawiamy na niniejszym video aktualne realizacje. Są to kompleksowe budowy domów jednorodzinnych, jak również wykończenie pod klucz apartamentów. Specjalizujemy się w nietuzinkowych projektach, wyróżniających się na tle konkurencji.
              </p>
            </div>
            <div className="w-full h-full">
              <video
                loop
                autoPlay
                muted
                playsInline
                className="w-full h-full object-cover"
              >
                <source
                  src={screenWidth < 720 ? video360p : video720p}
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
          <div className="max-w-full px-0 md:px-4 md:py-12 mx-auto lg:max-w-7xl">
            <div>
              <div className="grid grid-cols-2 gap-1 md:gap-4 lg:gap-12 md:grid-cols-3 lg:grid-cols-5 lg:space-y-0 lg:text-center">
                <div className="bg-white p-3  md:drop-shadow-md pt-8">
                  <div>
                    <div className="text-yellow-600	flex items-center justify-center  text-black  rounded-xl text-5xl lg:mx-auto">
                      <TfiStar />
                    </div>
                    <div className="text-yellow-600 mt-8 text-2xl font-medium leading-6 text-black text-center">
                      <ScrollTrigger onEnter={() => setCounterOn(true)}>
                        {counterOn && (
                          <CountUp start={0} end={6} duration={1} delay={0.5} />
                        )}
                      </ScrollTrigger>
                    </div>
                  </div>
                  <div className="mt-4 text-base text-gray-500 text-center">
                    Lat doświadczenia
                  </div>
                </div>
                <div className="bg-white p-3  md:drop-shadow-md pt-8">
                  <div>
                    <div className="text-yellow-600	 flex items-center justify-center  text-black  rounded-xl text-5xl lg:mx-auto">
                      <TfiHome />
                    </div>
                    <div className="text-yellow-600 mt-8 text-2xl font-medium leading-6 text-black text-center">
                      <ScrollTrigger onEnter={() => setCounterOn(true)}>
                        {counterOn && (
                          <CountUp
                            start={0}
                            end={19}
                            duration={1}
                            delay={0.5}
                          />
                        )}
                      </ScrollTrigger>
                    </div>
                  </div>
                  <div className="mt-4 text-base text-gray-500 text-center">
                    Wybudowanych domów
                  </div>
                </div>
                <div className="bg-white p-3  md:drop-shadow-md pt-8">
                  <div>
                    <div className="text-yellow-600	 flex items-center justify-center  text-black  rounded-xl text-5xl lg:mx-auto">
                      <TfiHummer />
                    </div>
                    <div className="text-yellow-600 mt-8 text-2xl font-medium leading-6 text-black text-center">
                      <ScrollTrigger onEnter={() => setCounterOn(true)}>
                        {counterOn && (
                          <CountUp
                            start={0}
                            end={22}
                            duration={1}
                            delay={0.5}
                          />
                        )}
                      </ScrollTrigger>
                    </div>
                  </div>
                  <div className="mt-4 text-base text-gray-500 text-center">
                    Domy w trakcie budowy
                  </div>
                </div>
                <div className="bg-white p-3  md:drop-shadow-md pt-8">
                  <div>
                    <div className="text-yellow-600	 flex items-center justify-center  text-black  rounded-xl text-5xl lg:mx-auto">
                      <TfiRulerPencil />
                    </div>
                    <div className="text-yellow-600 mt-8 text-2xl font-medium leading-6 text-black text-center">
                      <ScrollTrigger onEnter={() => setCounterOn(true)}>
                        {counterOn && (
                          <CountUp
                            start={0}
                            end={24}
                            duration={1}
                            delay={0.5}
                          />
                        )}
                      </ScrollTrigger>
                    </div>
                  </div>
                  <div className="mt-4 text-base text-gray-500 text-center">
                    Domy w fazie projektowania
                  </div>
                </div>
                <div className="bg-white p-3  md:drop-shadow-md pt-8">
                  <div>
                    <div className="text-yellow-600	flex items-center justify-center  text-black  rounded-xl text-5xl lg:mx-auto">
                      <TfiRulerAlt2 />
                    </div>
                    <div className="text-yellow-600 flex justify-center mt-8 text-2xl font-medium leading-6 text-black">
                      <ScrollTrigger onEnter={() => setCounterOn(true)}>
                        {counterOn && (
                          <CountUp
                            start={0}
                            end={13500}
                            duration={1}
                            delay={0.5}
                          />
                        )}
                      </ScrollTrigger>
                      m<sup>2</sup>
                    </div>
                  </div>
                  <div className="mt-4 text-base text-gray-500 text-center">
                    Łączna ilość m2 wszystkich projektów
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUs;
