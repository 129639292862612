import React from "react";
import heroBackgorund from "../../assets/images/faqBacgkround.jpg";

const Faq = () => {
  return (
    <>
      <section id="realization"
        className="bg-slate-50"
        style={{
          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, 0.1) 0%, rgba(0, 0, 0, 0.8) 100%),url(${heroBackgorund})`,
          backgroundPosition: "center top",
          backgroundSize: "cover",
          padding: "2rem",
        }}
      >
        <div className="relative items-center w-full md:px-5 pt-24 mx-auto md:px-12 lg:px-16 max-w-7xl lg:py-24">
          <div>
            <div className="max-w-2xl">
              <p
                className="text-4xl text-white"
                style={{
                  textShadow: "1px 1px 5px #000",
                }}
              >
                Najważniejsze kwestie do sprawdzenia przed zakupem działki /
                rozpoczęciem budowy domu.
              </p>
              <p
                className="max-w-2xl mt-4 text-lg tracking-tight text-white"
                style={{
                  textShadow: "1px 1px 5px #000",
                }}
              >
                Wszelkie analizy po naszej stronie
              </p>
            </div>
          </div>
          <div className="w-full mx-auto mt-12 text-left">
            <div className="relative items-center gap-12 m-auto lg:inline-flex md:order-first">
              <div className="md:p-4 mx-auto lg:max-w-7xl lg:p-0">
                <ul
                  role="list"
                  className="grid grid-cols-1 gap-4 list-none md:grid-cols-2 lg:grid-cols-3 lg:gap-12"
                >
                  <li
                    style={{
                      backgroundColor: "rgba(255,255,255,0.90)",
                      borderLeft: '5px solid #ca8a04'
                    }}
                    className="p-3  drop-shadow-md"
                    data-aos="fade-down"
                    data-aos-delay="100"
                    data-aos-offset="100"
                  >
                    <div>
                      <p className="mt-5 text-lg font-medium leading-6 text-black">
                        Sprawdzenie przeznaczenia działki w MPZP lub warunków
                        zabudowy.
                      </p>
                    </div>
                    <div className="mt-2 text-base text-gray-500">
                      Analiza dokumentacji planistycznej i ustalenie, jakie jest przeznaczenie działki i możliwości jej zabudowy.
                    </div>
                  </li>
                  <li
                    style={{
                      backgroundColor: "rgba(255,255,255,0.90)",
                      borderLeft: '5px solid #ca8a04'

                    }}
                    className="bg-white p-3  drop-shadow-md"
                    data-aos="fade-down"
                    data-aos-delay="120"
                    data-aos-offset="100"
                  >
                    <div>
                      <p className="mt-5 text-lg font-medium leading-6 text-black">
                        Sprawdzenie warunków na media oraz ewentualnych zgód od
                        właścicieli sąsiednich działek.
                      </p>
                    </div>
                    <div className="mt-2 text-base text-gray-500">
                      Wystąpienie o warunki przyłączeniowe i analiza trasy przyłączy mediów. Pod kątem ewentualnych zgód sąsiadów.
                    </div>
                  </li>
                  <li
                    style={{
                      backgroundColor: "rgba(255,255,255,0.90)",
                      borderLeft: '5px solid #ca8a04'

                    }}
                    className="bg-white p-3  drop-shadow-md"
                    data-aos="fade-down"
                    data-aos-delay="140"
                    data-aos-offset="100"
                  >
                    <div>
                      <p className="mt-5 text-lg font-medium leading-6 text-black">
                        Weryfikacja obsługi komunikacyjnej dla planowanej
                        inwestycji.
                      </p>
                    </div>
                    <div className="mt-2 text-base text-gray-500">
                      Wystąpienie o warunki do właściwego urzędu i sprawdzenie czy istniejąca droga jest wystarczająca
                    </div>
                  </li>
                  <li
                    style={{
                      backgroundColor: "rgba(255,255,255,0.90)",
                      borderLeft: '5px solid #ca8a04'

                    }}
                    className="bg-white p-3  drop-shadow-md"
                    data-aos="fade-down"
                    data-aos-delay="160"
                    data-aos-offset="100"
                  >
                    <div>
                      <p className="mt-5 text-lg font-medium leading-6 text-black">
                        Weryfikacja zakresu umowy z wykonawcami
                      </p>
                    </div>
                    <div className="mt-2 text-base text-gray-500">
                      Do wyboru odpowiedniego wykonawcy, należy dokładnie określić zakres umowy. Tak, aby podczas realizacji budowy nie zaskoczyły nas dodatkowe koszty.
                    </div>
                  </li>
                  <li
                    style={{
                      backgroundColor: "rgba(255,255,255,0.90)",
                      borderLeft: '5px solid #ca8a04'

                    }}
                    className="bg-white p-3  drop-shadow-md"
                    data-aos="fade-down"
                    data-aos-delay="180"
                    data-aos-offset="100"
                  >
                    <div>
                      <p className="mt-5 text-lg font-medium leading-6 text-black">
                        Dokładne zaplanowanie budżetu i weryfikacja wszystkich
                        kosztów.
                      </p>
                    </div>
                    <div className="mt-2 text-base text-gray-500">
                      Analiza szczegółowego budżetu, który obejmuje wszystkie koszty, które będą ponoszone podczas realizacji budowy.
                    </div>
                  </li>
                  <li
                    style={{
                      backgroundColor: "rgba(255,255,255,0.90)",
                      borderLeft: '5px solid #ca8a04'

                    }}
                    className="bg-white p-3  drop-shadow-md"
                    data-aos="fade-down"
                    data-aos-delay="200"
                    data-aos-offset="100"
                  >
                    <div>
                      <p className="mt-5 text-lg font-medium leading-6 text-black">
                        Sprawdzenie poprawności wykonania projektu budowlanego
                        oraz optymalizacja kosztów budowy.
                      </p>
                    </div>
                    <div className="mt-2 text-base text-gray-500">
                      Na etapie projektowania, można wybrać takie rozwiązania które nie wygenerują zwiększonych kosztów. Oraz nie będą trudne w wykonaniu.
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Faq;
