import React, { useEffect, useState } from "react";

import {
  NavBar,
  ShortHero,
  Project,
  Contact,
  Footer,
} from "../../components/index";

const Projects = () => {
  document.title = "HighHome.pl :: Projekty";


  const [data, setData] = useState();

  async function logJSONData() {
    const response = await fetch(
      "https://cdn.contentful.com/spaces/be9fg2lvstiv/entries/?content_type=projekty&access_token=vQ2zABxDf5ILJstpjSSmgBTgtBaLXTg_DHif_b7ueOk"
    );
    const jsonData = await response.json();
    setData(jsonData);
  }

  useEffect(() => {
    logJSONData();
    getAssets();
  }, []);

  const getAssets = () => {

    const aAssets = [];

    data?.includes?.Asset.forEach((assets) => {
      aAssets.push({ 'url': assets.fields.file.url })
    })

    return aAssets;
  }

  const renderProjects = () => {
    if (data?.items?.length > 0) {
      return (
        data?.items.map((item, key) => {

          const images = []
          const assetList = getAssets();

          item?.fields?.image?.forEach((sImage) => {
            assetList.forEach((asset) => {
              if (asset.url.includes(sImage.sys.id)) {
                images.push({
                  url: asset.url
                })
              }
            })
          })

          return (
            <Project
              data={
                {
                  data: item,
                  assets: images
                }
              }
            />
          )
        })
      )
    }

  }

  return (
    <>
      <NavBar />
      <ShortHero
        heading="Projekty"
        label="Odkryj nasze fascynujące projekty budowlane, łączące innowacyjne rozwiązania z doskonałą estetyką architektury."
        action="Zobacz projekty"
      />
      {renderProjects()}
      <Contact />
      <Footer />
      {/* <CallToUs/> */}
    </>
  );
};

export default Projects;
