import React from 'react'
import { HashLink } from 'react-router-hash-link'
import './ShortHero.css'

const ShortHero = ({ heading, label, action }) => {

    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    return (
        <>
            <section className="shortHero">
                <div className="items-center w-full px-5 py-12 mx-auto md:px-12 lg:px-16 max-w-7xl">
                    <div className="max-w-xl p-10 mx-auto text-center">
                        <div><p className="mt-8 text-5xl font-medium tracking-tighter text-black">
                            {heading || ""}
                        </p>
                            <p className="max-w-xl mx-auto mt-4 text-lg tracking-tight text-gray-600">
                                {label || ""}
                            </p>
                        </div>
                        {
                            action !== "undefined" && <div className="flex flex-col items-center justify-center gap-3 mt-10 lg:flex-row">
                                <HashLink
                                    to="/projekty#projects"
                                    smooth={true}
                                    scroll={el => scrollWithOffset(el)}
                                    className="items-center justify-center w-full px-6 py-2.5 text-center text-white duration-200 bg-black border-2 border-black rounded-full nline-flex hover:bg-transparent hover:border-black hover:text-black focus:outline-none lg:w-auto focus-visible:outline-black text-sm focus-visible:ring-black">
                                    {action || ""}
                                </HashLink>
                            </div>
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default ShortHero