import React, { useState } from 'react'
import Slider from "react-slick";

const Project = (props) => {

  window.scrollTo(0, 0)

  console.log("PROPS",props)

  const getQuarter = () => {

    const currentMonth = new Date().getMonth() + 1;

    if(currentMonth >= 1 && currentMonth <= 3) return "1"
    if(currentMonth >= 4 && currentMonth <= 6) return "2"
    if(currentMonth >= 7 && currentMonth <= 9) return "3"
    if(currentMonth >= 10 && currentMonth <= 12) return "4"

  }

  const settings = {
    dots: true,
    infinite: true,
    autoplay: false,
    autoplaySpeed: 5000,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <section
        id="projects"
        className={props.data?.data?.fields?.order === 1 ? ' bg-white' : ' bg-slate-50' + " relative flex items-center w-full overflow-hidden"}
      >
        <div className="relative items-center w-full md:px-5 md:py-24 mx-auto md:px-12 lg:px-16 max-w-7xl">
          <div className="relative flex-col items-start m-auto align-middle">
            <div className="grid grid-cols-1 mb-8 lg:grid-cols-2 lg:gap-12 md:mb-0">
              <div
                className={
                  props.data?.data?.fields?.order === 1
                    ? "min-h-[250px] md:min-h-fit md:order-first"
                    : "" + "min-h-[250px] md:min-h-fit"
                }
                data-aos="fade-down"
                data-aos-delay="100"
                data-aos-offset="100"
              >
                <div className="w-full h-full bg-white shadow-lg border-solid border-1">
                  <table className="w-full h-full flex flex-col justify-between text-xl p-6">
                    <tbody>
                      <tr className="w-full flex flex-row justify-between pb-2">
                        <td>
                          <span>Powierzchnia domu<br/><span className="">całkowita</span></span>
                        </td>
                        <td>
                          <span className="font-bold">{props.data?.data?.fields?.houseArea} m<sup>2</sup></span>
                        </td>
                      </tr>
                      <tr className="w-full flex flex-row justify-between pb-2">
                        <td>
                          <span>Powierzchnia domu<br/>użytkowa</span>
                        </td>
                        <td>
                          <span className="font-bold">{props.data?.data?.fields?.usableHouseArea} m<sup>2</sup></span>
                        </td>
                      </tr>
                      <tr className="w-full flex flex-row justify-between pb-2">
                        <td>
                          <span>Min. wymiary działki </span>
                        </td>
                        <td>
                          <span className="font-bold">{props.data?.data?.fields?.plotDimensions} m</span>
                        </td>
                      </tr>
                    </tbody>
                    <tfoot>
                      <tr className="w-full flex flex-row justify-between">
                        <td>
                          <span>Koszty budowy</span><br />
                          <span className="text-xs text-slate-500	">na {getQuarter()} kwartał {new Date().getFullYear()}</span>
                        </td>
                        <td className="text-4xl	">
                          <span className="text-yellow-600 font-bold">{props.data?.data?.fields?.constructionCost} zł</span>
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
              <div className="order-first mb-12 md:mb-0">
                <Slider
                  {...settings}
                  data-aos="fade-left"
                  data-aos-delay="100"
                  data-aos-offset="100"
                >
                  {
                    props?.data?.assets?.map((value, index) => {
                      return (
                        <div className="cursor-grab" key={index || null}>
                          <img
                            className="object-cover object-center w-full mx-auto 300 lg:ml-auto "
                            alt={'https:' + value.url || null}
                            src={'https:' + value.url || null}
                          />
                        </div>
                      )

                    })

                  }
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section >
    </>
  )
}

export default Project