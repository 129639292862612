import React from "react";
import { HashLink } from 'react-router-hash-link';
import heroBackgorund from "../../assets/images/home10.jpg";

const hero = () => {

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -80;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
  }


  return (
    <>
      <section
        id="home"
        style={{
          minHeight: `calc(100vh - 115px)`,
          backgroundImage: `linear-gradient(to bottom, rgba(0,0,0, 0.1) 0%, rgba(0, 0, 0, 0.5) 100%),url(${heroBackgorund})`,
        }}
        className="relative flex items-center w-full bg-cover bg-center overflow-hidden"
      >
        <div className="relative items-center w-full px-5 py-48 lg:h-max  mx-auto md:px-12 lg:px-16 max-w-7xl">
          <div className="relative flex-col items-start m-auto align-middle">
            <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 lg:gap-24">
              <div className="relative items-center gap-12 m-auto lg:inline-flex order-first">
                <div className="max-w-xl text-center lg:text-left">
                  <div>
                    <p
                      style={{
                        textShadow: "1px 1px 5px #000",
                      }}
                      className="text-2xl font-medium tracking-tight text-white sm:text-4xl drop-shadow-lg shadow-black"
                    >
                      Kompleksowa Budowa Domu na Zamówienie
                    </p>
                    <p
                      style={{
                        textShadow: "1px 1px 5px #000",
                      }}
                      className="max-w-xl mt-4 text-base tracking-tight text-white drop-shadow-lg"
                    >
                      Oferujemy usługę kompleksowej budowy domu na zamówienie.
                      Dzięki doświadczeniu przy realizacji projektów
                      deweloperskich, posiadamy pełne zaplecze do zrealizowania
                      budowy Twojego wymarzonego domu.
                    </p>
                  </div>
                  <div className="flex flex-col items-center justify-center gap-3 mt-10 lg:flex-row lg:justify-start">
                    <HashLink
                      to="#aboutUs"
                      scroll={el => scrollWithOffset(el)}
                      smooth={true}
                      className="cursor-pointer drop-shadow-lg items-center justify-center w-full px-6 py-2.5 text-center text-white duration-200 bg-black border-2 border-black rounded-full nline-flex hover:border-black hover:text-white focus:outline-none lg:w-auto focus-visible:outline-black text-sm focus-visible:ring-black"
                    >
                      Nasze usługi
                    </HashLink>
                    <HashLink
                      to="#realization"
                      scroll={el => scrollWithOffset(el)}
                      smooth={true}
                      className="cursor-pointer drop-shadow-lg inline-flex items-center justify-center text-sm font-semibold text-white duration-200 hover:text-yellow-600 focus:outline-none focus-visible:outline-gray-600"
                    >
                      Dowiedz się więcej
                      <span aria-hidden="true"> → </span>
                    </HashLink>
                  </div>
                </div>
              </div>
              <div className="flex justify-center order-first block w-full mt-12 lg:mt-0">
                {/* <video
                  loop
                  autoPlay
                  muted
                  className="hidden lg:block drop-shadow-lg scale-110 object-contain object-center w-full mx-auto lg:ml-auto"
                >
                  <source src={videos} type="video/mp4" />
                </video> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default hero;
