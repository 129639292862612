import React from "react";
import { Link } from "react-router-dom";

const Contact = () => {
  return (
    <>
      <section id="contact">
        <div className="items-center w-full px-5 py-24 mx-auto md:px-12 lg:px-16 max-w-7xl">
          <div className="text-left">
            <div className="items-center mx-auto lg:inline-flex">
              <div className="grid grid-cols-1 gap-6 lg:grid-cols-2 lg:gap-24">
                <div>
                  <p className="text-2xl font-medium tracking-tight text-black sm:text-4xl">
                    Lokalizacja oraz kontakt
                  </p>
                </div>
                <div className="lg:ml-auto">
                  <p className="mt-4 text-lg tracking-tight text-gray-600 lg:mt-0">
                    Jeśli szukasz informacji lub chciałbyś zadać pytanie,
                    zapraszamy do kontaktu. Nasz zespół jest tu po to, aby Ci
                    pomóc.{" "}
                    <Link to={"/"} className="text-yellow-600">
                      Skontaktuj się z nami
                    </Link>{" "}
                    pisząc na adres mailowy lub dzwoniąc pod numer telefonu,
                    który znajdziesz poniżej. Odpowiemy na Twoje pytania jak
                    najszybciej!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="relative items-center w-full mx-auto mt-12">
            <iframe
              title="map"
              className="w-full"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2561.1208837654226!2d19.87433191588572!3d50.065297722922466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47165be877ae9efb%3A0x3a97d0da237d32ed!2sKr%C3%B3lowej%20Jadwigi%20246%2C%2030-218%20Krak%C3%B3w!5e0!3m2!1spl!2spl!4v1680251475606!5m2!1spl!2spl"
              width="600"
              height="450"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div>
            <div className="pt-12 mx-auto lg:max-w-7xl">
              <dl className="grid grid-cols-1 gap-6 space-y-0 lg:gap-24 lg:grid-cols-3">
                <div>
                  <div>
                    <p className="text-lg font-medium leading-6 text-black">
                      Adres
                    </p>
                  </div>
                  <div className="mt-2 text-base text-gray-500">
                    Królowej Jadwigi 246/lokal nr 1, <br/>30-218 Kraków
                  </div>
                </div>
                <div>
                  <div>
                    <p className="text-lg font-medium leading-6 text-black">
                      Kontakt
                    </p>
                  </div>
                  <div className="mt-2 text-base text-gray-500">
                    <ul>
                      <li className="mb-2">
                        Telefon 1:{" "}
                        <Link
                          to={"tel:607186161"}
                          className="hover:text-yellow-600"
                        >
                          (+48) 607 186 161
                        </Link>
                      </li>
                      <li className="mb-2">
                        Telefon 2:{" "}
                        <Link
                          to={"tel:668646167"}
                          className="hover:text-yellow-600"
                        >
                          (+48) 668 646 167
                        </Link>
                      </li>
                      <li className="mb-2">
                        E-mail:{" "}
                        <Link
                          to={"mailto:kontakt@highhome.pl"}
                          className="hover:text-yellow-600"
                        >
                          kontakt@highhome.pl
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div>
                  <div>
                    <p className="text-lg font-medium leading-6 text-black">
                      Godziny otwarcia
                    </p>
                  </div>
                  <div className="mt-2 text-base text-gray-500">
                    <ul>
                      <li className="mb-2">
                        Poniedziałek - Piątek: 9:00 - 17:00
                      </li>
                      <li className="mb-2">Sobota - 9:00 - 16:00</li>
                    </ul>
                  </div>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
